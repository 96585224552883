import LayoutFooter from '@/components/layouts/LayoutFooter'
import LayoutHeader from '@/components/layouts/LayoutHeader'
import {
  MOBILE_HEADER_DEFAULT_HEIGHT,
  PC_HEADER_MAIN_HEIGHT,
} from '@/constants/Layout'
import useMetaContent from '@/hooks/fetch/useMetaContent'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { Fragment, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

interface LayoutProps {
  title?: string
  shrink?: boolean
  isHeaderTransparentAtTop?: boolean
}

function Layout({
  shrink = false,
  isHeaderTransparentAtTop,
  title,
  children,
  ...rest
}: PropsWithChildren<LayoutProps>) {
  const router = useRouter()
  const contentId = router.query?.contents?.[0] || 'main'

  const { meta } = useMetaContent()
  const { categories = [] } = meta

  const menu = categories.find(
    (menuItem) => menuItem.parentId && menuItem.contentId === contentId
  )
  const parentMenu = categories.find(
    (menuItem) => menuItem.id === menu?.parentId
  )

  const pageTitle = (() => {
    const contentDefaultPrefix = menu
      ? `${menu.label} | ${parentMenu ? `${parentMenu.label}` : ''}`
      : ''

    // props에 설정된 title이 있을 경우 최우선으로 적용,
    // '| 카카오모빌리티'문구는 추후 suffix 문구에서 중복되므로 제거함
    const titlePrefix =
      title || contentDefaultPrefix.replace('| 카카오모빌리티', '')

    return `${titlePrefix && `${titlePrefix} | `}카카오모빌리티`
  })()

  const LayoutStyleProps = {
    shrink,
    isHeaderTransparentAtTop,
  }

  return (
    <Fragment>
      <Head>
        <title key='title'>{pageTitle}</title>
        <meta property='og:title' key='og:title' content={pageTitle} />
        <meta name='twitter:title' key='twitter:title' content={pageTitle} />
      </Head>
      <Styled.SkipNav href='#main-content'>본문 바로가기</Styled.SkipNav>
      <Styled.Header {...LayoutStyleProps} />
      <Styled.Body {...LayoutStyleProps} {...rest} id='main-content'>
        {children}
      </Styled.Body>
      <Styled.Footer />
    </Fragment>
  )
}

const Styled = {
  Header: styled(LayoutHeader)``,
  SkipNav: styled.a`
    position: fixed;
    top: -30px;
    left: 0px;
    background: #000;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    padding: 0 6px;
    z-index: 9999;

    &:focus {
      top: 0px;
    }
  `,
  Body: styled.div<LayoutProps>`
    flex: 1;
    padding-top: ${PC_HEADER_MAIN_HEIGHT}px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: ${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    }
    ${({ isHeaderTransparentAtTop }) =>
      isHeaderTransparentAtTop &&
      css`
        padding-top: 0;
        @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
          padding-top: 0;
        }
      `};
  `,
  Footer: styled(LayoutFooter)``,
}

export default Layout
